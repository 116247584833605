
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from "@ionic/vue";
import { computed, reactive } from "@vue/reactivity";
import { cart, hammer, time } from "ionicons/icons";

import { idToName, retrieveVisitsOnDate } from "@/helpers";
import router from "@/router";
import { Visit } from "@/types/units";

interface State {
  visits: Array<Visit>;
}

export default {
  name: "Customer Day Item",
  props: {
    day: Object,
  },
  setup(props: any) {
    const state = reactive<State>({
      visits: [],
    });

    const initialize = async () => {
      // Initialze Visits (for purposes of hour computing)
      state.visits = await retrieveVisitsOnDate(props.day.date, {
        customerID: props.day.customerID,
      });
    };

    initialize();

    const propertyHours = computed(() =>
      state.visits.reduce((hours, visit) => hours + visit.data.time.hours, 0)
    );

    const employees = computed(() => {
      const ids: Array<string> = [];
      state.visits.forEach((visit: Visit) => {
        if (visit.data.employeeID && !ids.includes(visit.data.employeeID))
          ids.push(visit.data.employeeID);
      });
      return ids;
    });

    return {
      icons: { hammer, cart, time },
      idToName,
      router,
      state,
      propertyHours,
      employees,
    };
  },
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonIcon,
  },
};
