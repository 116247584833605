
import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from '@ionic/vue';
import { computed, reactive, ref, watch } from 'vue';

import config from '@/config/config';
import router from '@/router';
import store from '@/store';
import { CollectionRef, Query, Splitter } from '@/types/auxiliary';
import { EmployeeDayInterface } from '@/types/units';

import SearchToolbar from '../inputs/SearchToolbar.vue';
import InfiniteList from './InfiniteList.vue';
import EmployeeDayItem from './items/EmployeeDayItem.vue';

export default {
  name: 'Employee Days',
  components: {
    EmployeeDayItem,
    InfiniteList,
    SearchToolbar,
    IonRadioGroup,
    IonRadio,
    IonItem,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
  },
  props: {
    dbRef: Object,
    employeeID: String,
    hideAdd: Boolean,
    title: String,
    showPaidToggle: Boolean,
  },
  setup(props: any) {
    const state = reactive({
      filterUnpaid: false,
    });

    const daysRef = computed<Query>(() => {
      let ref: CollectionRef | Query = props.dbRef;
      if (state.filterUnpaid) {
        ref = ref.where('data.paid', '==', false);
      }
      return ref;
    });

    const key = ref(0);
    const searchDate = ref<string>('');

    watch(searchDate, () => (key.value += 1));
    watch(daysRef, () => (key.value += 1));

    const splitters = ref<Array<Splitter>>([
      {
        name: 'Future Dates',
        filter: (day: EmployeeDayInterface) => new Date(day.date) >= new Date(),
      },
      {
        name: 'Logged Dates',
        filter: (day: EmployeeDayInterface) => new Date(day.date) < new Date(),
      },
    ]);

    const sizes = {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 6,
      xl: 4,
    };

    const createDay = async () => {
      router.push({
        name: `New Employee Day / ${store.state.userType}`,
        params: { employeeID: props.employeeID },
      });
    };

    return {
      key,
      state,
      router,
      createDay,
      searchDate,
      splitters,
      sizes,
      daysRef,
      config,
    };
  },
};
