
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTextarea,
} from "@ionic/vue";
import { reactive } from "@vue/reactivity";
import { callOutline, mailOutline } from "ionicons/icons";

import Address from "@/components/Address.vue";
import AddButton from "@/components/buttons/AddButton.vue";
import config from "@/config/config";
import { showTextAreas } from "@/helpers";
import store from "@/store";
import { newImage } from "@/types/auxiliary";

import Images from "../lists/Images.vue";

export default {
  name: "Customer Form",
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  setup(props: any, { emit }: { emit: any }) {
    const state = reactive({
      customer: props.modelValue,
      showTextAreas: false,
    });

    showTextAreas(state);

    const addImage = () => {
      state.customer.data.propertyImages.unshift(newImage());
      emit("update:modelValue", state.customer);
    };

    const deleteImage = (index: number) => {
      state.customer.data.propertyImages.splice(index, 1);
      emit("update:modelValue", state.customer);
    };

    return {
      store,
      state,
      addImage,
      deleteImage,
      icons: {
        mailOutline,
        callOutline,
      },
      config,
    };
  },
  components: {
    IonInput,
    IonItem,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonTextarea,
    IonIcon,
    Address,
    AddButton,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonCardSubtitle,
    IonList,
    Images,
  },
};
