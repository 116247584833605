
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/vue";
import { reactive } from "@vue/reactivity";
import { callOutline,mailOutline } from "ionicons/icons";

import config from "@/config/config";
import store from "@/store";

export default {
  name: "Employee Form",
  components: {
    IonList,
    IonInput,
    IonItem,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCardContent,
  },
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  setup(props: any) {
    const state = reactive({
      employee: props.modelValue,
    });

    return {
      state,
      store,
      icons: {
        mailOutline,
        callOutline,
      },
      config,
    };
  },
};
