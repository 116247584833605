
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonToggle,
} from "@ionic/vue";
import { computed, reactive } from "@vue/reactivity";
import { timeOutline } from "ionicons/icons";

import { nameToID } from "@/helpers";
import { companiesCollection } from "@/main";
import store from "@/store";
import { CollectionRef, Query } from "@/types/auxiliary";
import { Employee } from "@/types/users";

import CurrencyInput from "./inputs/CurrencyInput.vue";
import DatePicker from "./inputs/DatePicker.vue";
import PaymentData from "./PaymentData.vue";

interface State {
  employee: Employee;
  filterByDate: boolean;
  startDate: string;
  endDate: string;
  filterUnpaid: boolean;
}

export default {
  name: "Payment Info",
  emits: ["toggleUnpaid", "update:modelValue"],
  props: {
    modelValue: Object,
  },
  setup(props: any) {
    // Set hourly rate dependent on whether employee is given
    const state = reactive<State>({
      employee: props.modelValue,
      filterByDate: false,
      startDate: "",
      endDate: "",
      filterUnpaid: true,
    });

    const daysRef = computed<Query>(() => {
      let ref: CollectionRef | Query = companiesCollection
        .doc(
          `${store.state.companyID}/employees/${nameToID(
            state.employee.data.id
          )}`
        )
        .collection("days");

      // Filter by paid status
      if (state.filterUnpaid) {
        ref = ref.where("data.paid", "==", false);
      }

      // Filter by time
      if (state.startDate && state.endDate) {
        ref = ref
          .where("data.date", ">=", state.startDate)
          .where("data.date", "<=", state.endDate);
      } else if (state.startDate) {
        ref = ref.where("data.date", ">=", state.startDate);
      } else if (state.endDate) {
        ref = ref.where("data.date", "<=", state.endDate);
      }

      return ref;
    });

    const expensesRef = computed<Query>(() => {
      let ref: CollectionRef | Query = companiesCollection
        .doc(
          `${store.state.companyID}/employees/${nameToID(
            state.employee.data.id
          )}`
        )
        .collection("expenses");

      // Filter by paid status
      if (state.filterUnpaid) {
        ref = ref.where("data.paid", "==", false);
      }
      // Filter by time
      if (state.startDate && state.endDate) {
        ref = ref
          .where("data.date", ">=", state.startDate)
          .where("data.date", "<=", state.endDate);
      } else if (state.startDate) {
        ref = ref.where("data.date", ">=", state.startDate);
      } else if (state.endDate) {
        ref = ref.where("data.date", "<=", state.endDate);
      }

      return ref;
    });

    const clearDates = () => {
      state.startDate = "";
      state.endDate = "";
    };

    return {
      store,
      state,
      timeOutline,
      daysRef,
      expensesRef,
      clearDates,
    };
  },
  components: {
    CurrencyInput,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonRadioGroup,
    IonRadio,
    IonIcon,

    IonToggle,
    IonCard,
    IonCardContent,
    PaymentData,
    DatePicker,
  },
};
