
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonSplitPane,
} from "@ionic/vue";
import { ref } from "vue";

export default {
  name: "Sections",
  props: {
    sections: Array,
    title: String,
  },
  setup(props: any) {
    const selectedSection = ref<string>(props.sections[0].id);

    return { selectedSection };
  },
  components: {
    IonSegment,
    IonSegmentButton,
    IonIcon,
    IonLabel,
    IonSplitPane,
    IonItem,
    IonContent,
    IonList,
  },
};
