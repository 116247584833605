
import { IonChip, IonGrid,IonLabel, IonNote, IonText } from "@ionic/vue";
import { computed, reactive } from "@vue/reactivity";

import { CollectionRef } from "@/types/auxiliary";
import { EmployeeDayInterface, ExpenseInterface } from "@/types/units";

interface State {
  days: Array<EmployeeDayInterface>;
  expenses: Array<ExpenseInterface>;
}

export default {
  name: "Payment Data",
  props: {
    daysRef: {
      type: Object as () => CollectionRef,
    },
    expensesRef: {
      type: Object as () => CollectionRef,
    },
    filterUnpaid: Boolean,
  },
  setup(props: any) {
    const state = reactive<State>({
      days: [],
      expenses: [],
    });

    const initialize = async () => {
      // Set up day and expense snapshots

      const dayDocs = (await props.daysRef.get()).docs;
      if (dayDocs) {
        state.days = dayDocs.map(
          (doc: any) => doc.data().data as EmployeeDayInterface
        );
      }
      const expenseDocs = (await props.expensesRef.get()).docs;
      if (expenseDocs) {
        state.expenses = expenseDocs.map(
          (doc: any) => doc.data().data as ExpenseInterface
        );
      }
    };

    initialize();

    const numDays = computed(() => state.days.length);

    const numHours = computed(() =>
      state.days.reduce(
        (acc: number, day: EmployeeDayInterface) => acc + day.time.hours * 1,
        0
      )
    );

    const hoursAmount = computed(() =>
      state.days.reduce(
        (acc: number, day: EmployeeDayInterface) =>
          acc + day.time.hours * (day.hourlyRate ? day.hourlyRate : 0),
        0
      )
    );

    const expensesAmount = computed(() =>
      state.expenses.reduce(
        (acc: number, expense: ExpenseInterface) => acc + expense.cost,
        0
      )
    );

    const totalAmount = computed(
      () => hoursAmount.value + expensesAmount.value
    );

    return {
      numDays,
      numHours,
      hoursAmount,
      expensesAmount,
      totalAmount,
    };
  },
  components: { IonText, IonNote, IonChip, IonLabel, IonGrid },
};
