
import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonNote,
  IonToggle,
  IonToolbar,
} from "@ionic/vue";
import { computed, reactive } from "@vue/reactivity";
import { cart, checkmark, hammer, time } from "ionicons/icons";

import { idToName, retrieveVisitsOnDate } from "@/helpers";
import { companiesCollection } from "@/main";
import router from "@/router";
import store from "@/store";
import { EmployeeDayInterface, Visit } from "@/types/units";

interface State {
  day: EmployeeDayInterface;
  visits: Array<Visit>;
}

export default {
  name: "Employee Day Item",
  props: {
    day: Object,
    showPaidToggle: Boolean,
  },
  emits: ["togglePaid"],
  setup(props: any) {
    const state = reactive<State>({
      day: props.day,
      visits: [],
    });

    const initialize = async () => {
      state.visits = await retrieveVisitsOnDate(state.day.date, {
        employeeID: state.day.employeeID,
      });
    };

    const customers = computed(() =>
      state.visits.reduce((customers: Array<string>, visit: Visit) => {
        if (visit.data.customerID && !customers.includes(visit.data.customerID))
          customers.push(idToName(visit.data.customerID));
        if (
          visit.data.unregisteredCustomer &&
          !customers.includes(visit.data.unregisteredCustomer)
        )
          customers.push(visit.data.unregisteredCustomer);
        return customers;
      }, [])
    );

    initialize();

    const togglePaid = async () => {
      state.day.paid = !state.day.paid;
      await companiesCollection
        .doc(
          `${state.day.companyID}/employees/${state.day.employeeID}/days/${state.day.date}`
        )
        .update({ "data.paid": state.day.paid });
    };

    const hourlyRate = computed(() => {
      if (state.day.hourlyRate) return state.day.hourlyRate;

      return null;
    });

    return {
      icons: { checkmark, hammer, time, cart },
      idToName,
      state,
      hourlyRate,
      router,
      togglePaid,
      customers,
      store,
    };
  },
  components: {
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonIcon,
    IonToolbar,
    IonCardTitle,
    IonNote,
    IonToggle,
    IonButtons,
    IonCardHeader,
  },
};
